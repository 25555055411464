/* eslint-disable no-use-before-define */
import { DiContainer, Injectable } from '@jack-henry/frontend-utils/di';
import { ObservationSource } from '@jack-henry/frontend-utils/observable';
import { ConfigurationService } from '@treasury/core/config';
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { SessionStorageService, exists, getKeys } from '@treasury/utils';
import LaunchDarkly, { LDClient } from 'launchdarkly-js-client-sdk';
import { EntitlementDto, getEntitlementName } from './entitlement.dto';
import { normalizeFeatureName } from './feature-flag.helpers';
import {
    EntitlementFeatureFlag,
    Feature,
    FeatureUser,
    LocalFeatureJson,
    TmFeatureFlag,
    TmFeatureType,
} from './feature-flag.types';

const STORAGE_PREFIX = 'TM_FF';

// TODO: consolidate this with BO feature flag service
@Injectable()
export class FeatureFlagService {
    constructor(
        private http: TmHttpClient,
        private storage: SessionStorageService,
        private config: ConfigurationService
        // eslint-disable-next-line no-empty-function
    ) {}

    private _features: Map<Lowercase<Feature>, TmFeatureFlag> = new Map();

    private initialized = false;

    private ldClient?: LDClient;

    private readonly readyStream = new ObservationSource<boolean>();

    public readonly ready$ = this.readyStream.toObservable();

    public get features() {
        return Array.from(this._features.values());
    }

    /** @deprecated Use DI instead of direct import. */
    static async isEnabled(...flags: string[]) {
        const instance = await getServiceInstance();
        return instance.isEnabled(...(flags as Feature[]));
    }

    /** @deprecated Use DI instead of direct import. */
    static async getFeatureFlags() {
        const instance = await getServiceInstance();
        // eslint-disable-next-line no-use-before-define
        return instance.getEntitlements();
    }

    static getInstance() {
        return getServiceInstance();
    }

    /**
     * Determine if one or more features are all enabled.
     * Consults local overrides first in dev builds.
     *
     * @param flagNames  A list of feature flag names to check.
     * @returns A promise containing `true` if—and only if—all features are enabled, otherwise `false`.
     */
    public async isEnabled(...flagNames: Feature[]) {
        await this.ready$.toPromise();

        return flagNames.every(name => {
            const locallyEnabled = this.getOverride(name);
            if (exists(locallyEnabled)) {
                return locallyEnabled;
            }

            const normalizedName = normalizeFeatureName(name);
            const feature = this._features.get(normalizedName);
            if (!feature) {
                console.warn(
                    `Attempted to query status of feature ${name}, but it was not present in the feature flag registry.`
                );

                return false;
            }

            return feature.type === TmFeatureType.LaunchDarkly
                ? this.ldClient?.variation(name, false)
                : feature.enabled;
        });
    }

    public setOverride(featureName: Feature, enabled: boolean) {
        this.storage.set(`${STORAGE_PREFIX}.${normalizeFeatureName(featureName)}`, enabled);
    }

    public getOverride(featureName: Feature) {
        return this.storage.get<boolean>(`${STORAGE_PREFIX}.${normalizeFeatureName(featureName)}`);
    }

    public async init(fiId: string, user: FeatureUser, localFlagsPath?: string) {
        if (this.ready$.completed || this.initialized) {
            return;
        }

        let features: TmFeatureFlag[] = [];

        try {
            const promises = [
                this.getEntitlementFeatures(),
                this.createLdClient(fiId, user).catch(e => {
                    console.error(
                        'Could not initialize Launch Darkly  client. LD flags will be read as disabled.'
                    );
                    return [];
                }),
            ];

            if (localFlagsPath) {
                promises.push(this.getLocalFeatures(localFlagsPath));
            }

            features = (await Promise.all(promises)).flat();
        } catch (e) {
            this.readyStream.setError(new Error('Could not initialize feature flag service.'));
            this.readyStream.complete();
            throw e;
        }

        this.storeFeatureArray(features);
        this.initialized = true;
        this.readyStream.emit(true);
    }

    public async reset() {
        this._features.clear();
        await this.ldClient?.close();
        this.ldClient = undefined;
        this.initialized = false;
        this.readyStream.emit(false);
    }

    private async getLocalFeatures(path: string): Promise<TmFeatureFlag[]> {
        try {
            const json = await this.http.request<LocalFeatureJson>(path, {
                method: 'GET',
                responseType: TmHttpResponseType.Json,
                // disable base URL to request non-FI-scoped asset
                withoutBase: true,
            });

            return getKeys(json).map(k => {
                const value = json[k];
                const fiId = this.config.institutionId;
                const enabled = Array.isArray(value)
                    ? // normalize values to allow for all casing permutations
                      value.map(v => v.toLowerCase()).includes(fiId.toLowerCase())
                    : !!value;

                return {
                    type: TmFeatureType.Local,
                    name: k,
                    enabled,
                };
            });
        } catch (e) {
            // fail back to empty array and warn if local feature flags are not available
            console.warn(`Could not load local feature flags from ${path}.`);
            return [];
        }
    }

    /**
     * Get entitlements and convert them to a dictionary of `Feature` objects.
     * @returns
     */
    private async getEntitlementFeatures() {
        const entitlements = await this.getEntitlements();
        return entitlements.map(e => new EntitlementFeatureFlag(e));
    }

    /**
     * Get the underlying entitlement DTOs used to inform feature flag states.
     */
    private async getEntitlements() {
        const entitlements = await this.http.request<EntitlementDto[]>(`entitlements`, {
            method: 'GET',
            maxAgeInSeconds: 50000,
        });

        return entitlements.filter(e => getEntitlementName(e).toLowerCase().includes('feature'));
    }

    /**
     * Create an `LDClient` instance, assign it as a class member,
     * fetch its features and transform them into a `TmFeatureFlag` array.
     */
    private async createLdClient(fiId: string, user: FeatureUser) {
        const { app, ffToken } = this.config;
        const { companyId, userId, isAdmin, isSuperUser } = user;
        const ldClient = LaunchDarkly.initialize(
            ffToken,
            {
                kind: 'multi',
                user: {
                    kind: 'user',
                    key: userId,
                    isAdmin,
                    isSuperUser,
                },
                fi: {
                    kind: 'fi',
                    key: fiId.toLowerCase(),
                    companyId,
                },
                app: {
                    kind: 'app',
                    key: app,
                },
            },
            {
                sendEventsOnlyForVariation: true,
            }
        );

        await ldClient.waitForInitialization(20);

        this.ldClient = ldClient;

        return getLdFlags(ldClient);
    }

    private storeFeatureArray(features: TmFeatureFlag[]) {
        features.forEach(f => {
            this._features.set(normalizeFeatureName(f.name), f);
        });
    }
}

async function getLdFlags(ldClient: LDClient): Promise<TmFeatureFlag[]> {
    const flags = await ldClient.allFlags();

    return Object.keys(flags).map(featureName => {
        const value = flags[featureName];

        return {
            type: TmFeatureType.LaunchDarkly,
            name: featureName as Feature,
            enabled: !!value,
        };
    });
}

async function getServiceInstance() {
    return (await DiContainer.getInstance()).getAsync(FeatureFlagService);
}
