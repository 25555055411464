import { Injectable } from '@jack-henry/frontend-utils/di';
import { ObservationSource } from '@jack-henry/frontend-utils/observable';
import { parseTime } from '@treasury/alarm-clock';
import { CompanyAccountsClient } from '@treasury/api/channel';
import { AppType, ConfigurationService } from '@treasury/core/config';
import { removeAuthToken } from '@treasury/core/http';
import { SessionStorageService } from '@treasury/utils';
import { AccountRequests } from '../../backoffice/requests';
import { BoAccountService } from '../../backoffice/services/account';
import {
    AccountService as ChannelAccountService,
    EntitlementsService,
} from '../../channel/services';
import { UsersService } from '../../users';
import { AuthenticationService } from '../authentication';
import { IdleSignoutService } from './idle-signout-service';
import { LogoutReason } from './logout.types';
import { TimeAccessLogout } from './time-access-logout';

@Injectable()
export class LogoutManager {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        private readonly config: ConfigurationService,
        private readonly authService: AuthenticationService,
        private readonly channelAccountService: ChannelAccountService,
        private readonly boAccountService: BoAccountService,
        private readonly usersService: UsersService,
        private readonly idleSignoutService: IdleSignoutService,
        private readonly timeAccessLogout: TimeAccessLogout,
        private readonly companyAccountsClient: CompanyAccountsClient,
        private readonly sessionStorageService: SessionStorageService
    ) {
        this.idleSignoutService.sessionEnd$.subscribe(reason => this.logOut(reason));
    }

    private loggingOut = false;

    private logoutSource = new ObservationSource<void>();

    private idleDuration?: number;

    public logout$ = this.logoutSource.toObservable();

    /**
     * Starts or restarts the current logout timers.
     */
    public async startLogoutTimers() {
        this.loggingOut = false;
        this.startIdleSignout();
        this.startTimeAccessExpiredSignout();
    }

    public async logOut(reason: LogoutReason) {
        let result = null;
        if (this.loggingOut) {
            return null;
        }

        try {
            this.loggingOut = true;
            this.timeAccessLogout.stop();
            EntitlementsService.instance.reset();
            this.authService.invalidate();
            const logoutUrl = (await this.performLogoutRequest(reason)) || '';
            result = { link: logoutUrl };

            this.logoutSource.emit();
        } finally {
            this.sessionStorageService.clear();
            removeAuthToken();
            this.loggingOut = false;
        }

        return result;
    }

    private async startIdleSignout() {
        if (this.loggingOut) {
            return;
        }

        if (!this.idleDuration) {
            const { idleTimeoutDuration } = await this.getUserSettings();
            this.idleDuration = idleTimeoutDuration || 0;
        }

        this.idleSignoutService.start(this.idleDuration);
    }

    private async getCurrentFiTime() {
        const time =
            this.config.app === AppType.BackOffice
                ? await AccountRequests.getCurrentFiDateTime()
                : await this.companyAccountsClient
                      .companyAccountsGetCutoffTimes({ maxAgeInSeconds: 600 })
                      .then(response => response.data.currentFiTime);
        return parseTime(time);
    }

    private async startTimeAccessExpiredSignout() {
        if (this.loggingOut) {
            return;
        }

        const fiCurrentTime = await this.getCurrentFiTime();

        const timeAccessSettings = await this.usersService.getCurrentUserTimeAccess();

        this.timeAccessLogout.startLogoutTimer(fiCurrentTime, timeAccessSettings, reason =>
            this.logOut(reason)
        );
    }

    private getUserSettings() {
        // user settings DTOs are consistent between services
        return this.config.app === AppType.BackOffice
            ? this.boAccountService.getUserSettings()
            : this.channelAccountService.getUserSettings();
    }

    private performLogoutRequest(reason: string) {
        return this.config.app === AppType.BackOffice
            ? this.boAccountService.logout(reason)
            : this.channelAccountService.logout(reason);
    }
}
